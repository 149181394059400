import React from 'react'
import { MDXProvider } from '@mdx-js/react'

// Context
import ThemeProvider from './src/context/ThemeProvider'

const TableWrapper = ({ children }) => (
  <div className="table-wrapper">
    <table>{children}</table>
  </div>
)

const components = {
  table: TableWrapper,
}

const BootstrapWrapper = ({ element }) => {
  return (
    <ThemeProvider>
      <MDXProvider components={components}>{element}</MDXProvider>
    </ThemeProvider>
  )
}

export default BootstrapWrapper
