import React, { createContext } from 'react'

// Utils
import useWindowDimensions from '../utils/useWindowDimensions'

const defaultContext = {
  windowWidth: undefined,
  windowHeight: undefined,
}

export const ThemeContext = createContext(defaultContext)

const ThemeProvider = ({ children }) => {
  const { windowWidth, windowHeight } = useWindowDimensions()

  return (
    <ThemeContext.Provider value={{ windowWidth, windowHeight }}>{children}</ThemeContext.Provider>
  )
}

export default ThemeProvider
