import { useState, useEffect } from 'react'

const useWindowDimensions = () => {
  const [size, setSize] = useState({
    windowWidth: undefined,
    windowHeight: undefined,
  })

  const updateSize = () =>
    setSize({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

export default useWindowDimensions
