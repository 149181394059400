import BootstrapWrapper from './BootstrapWrapper'

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@popperjs/core/dist/umd/popper.min.js'

// Styles
import './src/styles/reset.css'
import './src/styles/index.css'

export const wrapRootElement = BootstrapWrapper
